import { useI18n } from "vue-i18n";
export default function useTranslate() {
  const { t, te } = useI18n();
  const translate = (text) => {
    if (te(text)) {
      return t(text);
    } else {
      return text;
    }
  };
  return {
    translate,
  };
}
