import { createApp } from "vue";
import ElementPlus from "element-plus";
import "@/assets/sass/style.scss";
import "@/assets/sass/global.scss";

import App from "./App.vue";

import router from "./router";
import store from "./store";
import fr from "element-plus/es/locale/lang/fr";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import vClickOutside from "click-outside-vue3";

import { MenuComponent, ToggleComponent } from "@/assets/ts/components";
import "bootstrap";

/**
 * Initialize custom components
 */
setTimeout(() => {
  ToggleComponent.bootstrap();
  MenuComponent.bootstrap();
}, 500);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(vClickOutside);

app.use(ElementPlus, {
  locale: fr,
});

initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
