export const nest = ({ items, id = 1, link = "parentTicketId" }) =>
  items
    .filter((item) => item[link] === id)
    .map((item) => ({
      data: item,
      children: nest({ items, id: item.id, link }),
    }));

export const getChilds = ({ items, id = 1, link = "parentTicketId" }) =>
  items
    .filter((item) => item[link] === id)
    .map((item) => [item.id, ...getChilds({ items, id: item.id, link })])
    .flat();

export const createUniqueId = (items) => {
  const id = Math.floor(Math.random() * 99999) + 1;
  const isUnique = items.findIndex((x) => x.id == id) == -1;
  return isUnique ? id : createUniqueId(items);
};
