import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  SubscribeUserResponseDto,
  JwtTokensDto,
  UserGroupRequestDto,
  AllGroupsResponseDto,
} from "@/service/index";
import service from "@/service/devFactory";
/* eslint-disable  @typescript-eslint/no-explicit-any */

interface User {
  firstName: string;
  lastName: string;
  password: string;
  title: string;
  skills: [string];
  email: string;
  id: number;
  positionType: "Full Stack" | "Web Developer" | "Expert Referencing";
}

interface UserInfo {
  user: User;
}

interface SubscribeUserResponseInfo {
  subscribeResult: SubscribeUserResponseDto;
}

interface TokensInfo {
  token: JwtTokensDto;
}

interface InviteUserInfo {
  invitedUser: UserGroupRequestDto;
}

interface GroupInfo {
  groups: AllGroupsResponseDto;
}

interface UsersInfo {
  users: any;
}
@Module
export default class UserModule
  extends VuexModule
  implements
    UserInfo,
    SubscribeUserResponseInfo,
    TokensInfo,
    InviteUserInfo,
    GroupInfo,
    UsersInfo
{
  user = {} as User;
  subscribeResult = {} as SubscribeUserResponseDto;
  token = {} as JwtTokensDto;
  invitedUser = {} as UserGroupRequestDto;
  groups = {} as AllGroupsResponseDto;
  users = {} as UsersInfo;

  get getUser(): User {
    return this.user;
  }

  get resultSubscribeUser(): SubscribeUserResponseDto {
    return this.subscribeResult;
  }

  get resultLoginUser(): JwtTokensDto {
    return this.token;
  }

  get getInviteUser(): UserGroupRequestDto {
    return this.invitedUser;
  }
  get getGroups(): AllGroupsResponseDto {
    return this.groups;
  }

  get userToken(): JwtTokensDto {
    return this.token;
  }

  get usersInfo(): any {
    return this.users;
  }

  @Mutation
  [Mutations.SET_LOGIN_MUTATION](payload) {
    this.token = payload;
  }

  @Mutation
  [Mutations.SET_INFO_ALL_USERS](payload) {
    this.users = payload;
  }

  @Mutation
  [Mutations.SET_SUBSCRIBE_ADMIN_MUTATION](payload) {
    this.subscribeResult = payload;
  }

  @Mutation
  [Mutations.SET_USER_ACCESS_TOKEN_MUTATION](payload) {
    this.user = {
      ...this.user,
      ...{
        email: payload.data.attributes.email,
        id: parseInt(payload.data.id),
      },
    };
  }

  @Mutation
  [Mutations.SET_INVITE_USER_MUTATION](payload) {
    this.invitedUser = payload;
  }

  @Mutation
  [Mutations.SET_INFO_USER](payload) {
    this.user = {
      ...this.user,
    };
    this.subscribeResult = payload;
  }

  @Mutation
  [Mutations.SET_GROUP_MUTATION](payload) {
    this.groups = payload;
  }

  @Mutation
  [Mutations.SET_USER_TOKEN](payload) {
    this.token = payload;
  }

  @Mutation
  [Mutations.SET_USER_PROFILE](payload) {
    this.user = payload;
  }

  @Action
  async [Actions.FETCH_USER_ACCESS_TOKEN](payload) {
    await service.v1
      .authControllerGetUserByAccessToken({
        token: payload.data,
      })
      .then((res) => {
        this.context.commit(Mutations.SET_USER_ACCESS_TOKEN_MUTATION, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR_MUTATION, error.error.error);
      });
  }

  @Action
  async [Actions.FETCH_INFO_USER](payload) {
    const userInfo = {
      ...this.user,
      ...payload.data,
    };
    await service.v1
      .authControllerSubscribeMember({
        ...userInfo,
      })
      .then((res) => {
        this.context.commit(Mutations.SET_INFO_USER, res.data.data);
        localStorage.setItem(
          "token",
          String(res.data.data?.jwtTokens?.accessToken)
        );
        service.setBaseApiParams({
          headers: {
            Authorization: "Bearer " + res.data.data?.jwtTokens?.accessToken,
          },
        });
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR_MUTATION, error.error.error);
      });
  }

  @Action
  async [Actions.FETCH_SUBSCRIBE_ADMIN](payload) {
    await service.v1
      .authControllerSubscribeAdmin({
        email: payload.data.email,
        firstName: payload.data.name,
        lastName: payload.data.surname,
        phone: payload.data.phoneNumber,
        password: payload.data.password,
        website: {
          name: payload.data.accountName,
          image: payload.data.accountPlan,
          sizeWebsite: payload.data.accountTeamSize,
        },
        card: {
          name: payload.data.nameOnCard,
          number: payload.data.cardNumber,
          month: payload.data.cardExpiryMonth,
          year: payload.data.cardExpiryYear,
          cvv: payload.data.cardCvv,
        },
        business: {
          name: payload.data.businessName,
          shortenedDescriptor: payload.data.businessDescriptor,
          description: payload.data.businessDescription,
          email: payload.data.businessEmail,
          businessType: payload.data.businessType,
        },
      })
      .then((res) => {
        this.context.commit(
          Mutations.SET_SUBSCRIBE_ADMIN_MUTATION,
          res.data.data
        );
        localStorage.setItem(
          "token",
          String(res.data.data?.jwtTokens?.accessToken)
        );
        service.setBaseApiParams({
          headers: {
            Authorization: "Bearer " + res.data.data?.jwtTokens?.accessToken,
          },
        });
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR_MUTATION, error.error.error);
      });
  }

  @Action
  async [Actions.FETCH_LOGIN](payload) {
    await service.v1
      .authControllerSignIn({
        email: payload.data.email,
        password: payload.data.password,
      })
      .then((res) => {
        this.context.commit(Mutations.SET_LOGIN_MUTATION, res.data.data);
        localStorage.setItem("token", String(res.data.data?.accessToken));
        service.setBaseApiParams({
          headers: { Authorization: "Bearer " + res.data.data?.accessToken },
        });
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR_MUTATION, error.error.error);
      });
  }

  @Action
  async [Actions.FETCH_INVITE_USER](payload) {
    await service.v1
      .usersControllerAddUserWithGroups({
        email: payload.data.email,
        firstName: payload.data.firstName,
        groups: payload.data.groups,
        lastName: payload.data.lastName,
      })

      /* eslint-disable  @typescript-eslint/no-explicit-any */
      .then((res: any) => {
        this.context.commit(Mutations.SET_INVITE_USER_MUTATION, res.data?.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ERROR_MUTATION, error.error.error);
      });
  }

  @Action
  async [Actions.FETCH_GROUPS]() {
    const { data } = await service.v1.groupsControllerFindAll();
    this.context.commit(Mutations.SET_GROUP_MUTATION, data.data);
  }

  @Action
  async [Actions.FETCH_INFO_ALL_USERS]() {
    const { data } = await service.v1.usersControllerGetAllVerifiedUsers();
    this.context.commit(Mutations.SET_INFO_ALL_USERS, data.data);
  }

  @Action
  async [Actions.FETCH_USER_TOKEN]() {
    this.context.commit(
      Mutations.SET_USER_TOKEN,
      localStorage.getItem("token")
    );
  }
  @Action
  async [Actions.FETCH_USER_PROFILE]() {
    await service.v1
      .authControllerGetUserByAccessToken({
        token: localStorage.getItem("token") || "",
      })
      .then((res: any) => {
        this.context.commit(Mutations.SET_USER_PROFILE, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_USER_TOKEN, null);
        this.context.commit(Mutations.SET_ERROR_MUTATION, error.error.error);
      });
  }
}
