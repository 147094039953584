import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type {
  GetPlatformResponseDto,
  GetPlatformOverviewResponseDto,
  PlatformResponseDto,
  AllPlatformsResponseDto,
} from "@/service/index";
import service from "@/service/devFactory";
import { DeepPartial } from "@/types/helper";

@Module
export default class PlatformModule extends VuexModule {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  platformInfo = {} as GetPlatformResponseDto;
  platformOverview: DeepPartial<GetPlatformOverviewResponseDto> = {
    tasks: [],
    platform: {},
    projects: [],
    latestFiles: [],
  };
  platformResponse: DeepPartial<PlatformResponseDto> = {
    id: 0,
  };
  allPlatformsResponse = {} as AllPlatformsResponseDto;

  get platformFormInfos(): GetPlatformResponseDto {
    return this.platformInfo;
  }

  get platformOverviewData() {
    return this.platformOverview;
  }

  get allPlatforms(): AllPlatformsResponseDto {
    return this.allPlatformsResponse;
  }

  @Mutation
  [Mutations.SET_CREATE_PLATFORM_MUTATION](payload) {
    this.platformResponse = payload;
  }
  @Mutation
  [Mutations.SET_FORM_INFO_PLATFORM_MUTATION](payload) {
    this.platformInfo = payload;
  }
  @Mutation
  [Mutations.SET_PLATFORM_OVERVIEW](payload) {
    this.platformOverview = payload;
  }
  @Mutation
  [Mutations.SET_ALL_PLATFORMS](payload) {
    this.allPlatformsResponse = payload;
  }

  @Action
  async [Actions.FETCH_CREATE_PLATFORM](payload) {
    const typesPlatform: [{ id: any; name: any; technologies: Array<any> }] = [
      {
        id: payload.data.technologiesApplication[0].id,
        name: payload.data.technologiesApplication[0].name,
        technologies: [],
      },
    ];

    payload.data.technologiesApplication.map((type) => {
      if (typesPlatform.map((e) => e.id).indexOf(type.id) <= -1) {
        typesPlatform.push({ id: type.id, name: type.name, technologies: [] });
      }
      typesPlatform[
        typesPlatform.map((e) => e.id).indexOf(type.id)
      ]?.technologies?.push(type["technologies"].name);
      return typesPlatform;
    });

    const { data } = await service.v1.platformsControllerCreate({
      name: payload.data.platformName,
      ticketsPrefix: payload.data.keyValue,
      description: payload.data.platformDescription,
      activities: payload.data.activities,
      adminUser: payload.data.teamManager,
      groups: payload.data.groupSelected,
      languages: payload.data.languageSelected,
      releaseDate: payload.data.releaseDate,
      platformFiles: [
        {
          fileTitle: `${Math.floor(Math.random() * 99999) + 1}`,
        },
      ],
      types: typesPlatform,
    });
    try {
      const result = { data };
      this.context.commit(Mutations.SET_CREATE_PLATFORM_MUTATION, result.data);
      return result.data["id"];
    } catch {
      this.context.commit(Mutations.SET_ERROR_MUTATION, data);
    }
  }

  @Action
  async [Actions.FETCH_FORM_INFO_PLATFORM]() {
    await service.v1
      .platformsControllerFindOneFormPlatform({})
      .then((res) => {
        this.context.commit(
          Mutations.SET_FORM_INFO_PLATFORM_MUTATION,
          res.data
        );
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_FORM_INFO_PLATFORM_MUTATION, error);
      });
  }
  @Action
  async [Actions.FETCH_PLATFORM_OVERVIEW](payload) {
    await service.v1
      .platformsControllerFindOne(payload.id)
      .then((res) => {
        this.context.commit(Mutations.SET_PLATFORM_OVERVIEW, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_PLATFORM_OVERVIEW, error);
      });
  }

  @Action
  async [Actions.FETCH_ALL_PLATFORMS](payload) {
    await service.v1
      .platformsControllerFindAll({
        page: payload.data.page,
        limit: payload.data.rowsPerPage,
      })
      .then((res) => {
        this.context.commit(Mutations.SET_ALL_PLATFORMS, res.data);
      })
      .catch((error) => {
        this.context.commit(Mutations.SET_ALL_PLATFORMS, error);
      });
  }
}
