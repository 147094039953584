enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  FETCH_SUBSCRIBE_ADMIN = "fetchSubscribeAdmin",
  FETCH_GROUPS = "fetchGroups",
  FETCH_MODAL = "fetchModal",
  FETCH_LOGIN = "fetchLogin",
  FETCH_MENU_PLATFORM = "fetchMenuPlatform",
  FETCH_INVITE_USER = "fetchInviteUser",
  FETCH_USER_ACCESS_TOKEN = "fetchUserAccessToken",
  FETCH_INFO_USER = "fetchInfoUser",
  FETCH_LIST_SELECT_TICKETS = "fetchListSelectTickets",
  FETCH_LIST_TICKETS_STATUS = "fetchListTicketsStatus",
  FETCH_LIST_ALL_TICKETS = "fetchListAllTickets",
  FETCH_CREATE_PLATFORM = "fetchCreatePlatform",
  FETCH_FORM_INFO_PLATFORM = "fetchFormInfoPlatform",
  REFRESH_TOKEN = "refreshToken",
  FETCH_LIST_TICKETS_LINKS = "fetchListTicketsLinks",
  SUBMIT_NEW_TICKET = "SUBMIT_NEW_TICKET",
  SET_ERROR = "setError",
  CLEAR_ERROR = "clearError",
  FETCH_PLATFORM_OVERVIEW = "fetchPlatformOverview",
  FETCH_USER_TOKEN = "fetchUserToken",
  FETCH_USER_PROFILE = "fetchUserProfile",
  FETCH_ALL_PLATFORMS = "fetchAllPlatforms",
  MINDMAP_SET_ACTIVE_NODE = "mindmapSetActiveNode",
  FETCH_COMPLEXITIES = "fetchComplexities",
  FETCH_NODE_TYPES = "fetchNodeTypes",
  SUBMIT_MINDMAP_NODE = "submitMindmapNode",
  FETCH_INFO_ALL_USERS = "fetchInfo_All_Users",
  FETCH_CALCULATE_ESTIMATION = "fetchCalculateEstimation",
  ADD_MODULE = "addModule",
  SET_USERDETAILS_ACTIONS = "UserDetails",
  LOAD_NODES = "loadNodes",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_GROUP_MUTATION = "setGroupMutation",
  SET_MODAL_MUTATION = "setModalMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_LOGIN_MUTATION = "setLoginMutation",
  SET_SUBSCRIBE_ADMIN_MUTATION = "setSubscribeAdminMutation",
  SET_MENU_PLATFORM_MUTATION = "setMenuPlatformMutation",
  SET_INVITE_USER_MUTATION = "setInviteUserMutation",
  SET_USER_ACCESS_TOKEN_MUTATION = "setUserAccessTokenMutation",
  SET_INFO_USER = "setInfoUser",
  SET_LIST_SELECT_TICKETS_MUTATION = "setListSelectTicketsMutation",
  SET_LIST_TICKETS_STATUS_MUTATION = "setListTicketsStatusMutation",
  SET_LIST_ALL_TICKETS_MUTATION = "setListAllTicketsMutation",
  SET_CREATE_PLATFORM_MUTATION = "setCreatePlatformMutation",
  SET_FORM_INFO_PLATFORM_MUTATION = "setFormInfoPlatformMutation",
  REFRESH_TOKEN_MUTATION = "refreshTokenMutation",
  SET_LIST_TICKETS_LINKS_MUTATION = "setListTicketsLinksMutation",
  SET_ERROR_MUTATION = "setErrorMutation",
  CLEAR_ERROR_MUTATION = "clearErrorMutation",
  SET_PLATFORM_OVERVIEW = "setPlatformOverview",
  SET_USER_TOKEN = "setUserToken",
  SET_USER_PROFILE = "setUserProfile",
  SET_ALL_PLATFORMS = "setAllPlatforms",
  MINDMAP_SET_ACTIVE_NODE = "mindmapSetActiveNode",
  SET_COMPLEXITIES = "setComplexities",
  SET_NODE_TYPES = "setNodeTypes",
  MINDMAP_ADD_NODE = "mindmapAddNode",
  MERGE_MINDMAP_NODE = "mergeMindmapNode",
  DELETE_MINDMAP_NODE = "deleteMindmapNode",
  SET_INFO_ALL_USERS = "setInfoAllUsers",
  MERGE_MODULE_NODES = "mergeModuleNodes",
  SET_NODES = "setNodes",
  SET_USER_ID = "setUserId",
}

export { Actions, Mutations };
