import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface errorInterface {
  message: string;
  statusCode: number;
}
interface ErrorInfo {
  error: errorInterface;
}

@Module
export default class ErrorModule extends VuexModule implements ErrorInfo {
  error = {} as errorInterface;

  get getError(): errorInterface {
    return this.error;
  }

  @Mutation
  [Mutations.SET_ERROR_MUTATION](payload) {
    this.error = payload;
  }

  @Mutation
  [Mutations.CLEAR_ERROR_MUTATION]() {
    this.error = { message: "", statusCode: 200 };
  }

  @Action
  [Actions.SET_ERROR](payload) {
    this.context.commit(Mutations.SET_ERROR_MUTATION, payload);
  }

  @Action
  [Actions.CLEAR_ERROR]() {
    this.context.commit(Mutations.CLEAR_ERROR_MUTATION);
  }
}
