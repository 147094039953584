import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  GetListSelectTicketResponseDto,
  GetStatusTicketsResponseDto,
  AllTicketsResponseDto,
  CreateTicketRequestDto,
  NameOrIdDto,
  GetTicketsLinksResponseDto,
} from "@/service/index";
import service from "@/service/devFactory";
import type { CreateNewTicketFormData } from "@/types/ticket";

interface SelectTicketInfo {
  tickets: GetListSelectTicketResponseDto;
}

interface ListTicketsByStatus {
  ticketsList: GetStatusTicketsResponseDto;
}

interface ListAllTickets {
  allTickets: AllTicketsResponseDto;
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
interface ListTicketsLinks {
  data: any;
}

interface TicketInfoByLinks {
  ticketLinks: GetTicketsLinksResponseDto;
}

@Module
export default class SelectTicketModule
  extends VuexModule
  implements
    SelectTicketInfo,
    ListTicketsByStatus,
    ListAllTickets,
    TicketInfoByLinks
{
  tickets = {} as GetListSelectTicketResponseDto;
  ticketsList = {} as GetStatusTicketsResponseDto;
  allTickets = {} as AllTicketsResponseDto;
  ticketLinks = {} as GetTicketsLinksResponseDto;

  get getTickets(): GetListSelectTicketResponseDto {
    return this.tickets;
  }

  get listTicketsByStatus(): GetStatusTicketsResponseDto {
    return this.ticketsList;
  }

  get listAllTickets(): AllTicketsResponseDto {
    return this.allTickets;
  }

  get listTicketLinks(): ListTicketsLinks {
    return this.ticketLinks;
  }

  @Mutation
  [Mutations.SET_LIST_SELECT_TICKETS_MUTATION](payload) {
    this.tickets = payload;
  }

  @Mutation
  [Mutations.SET_LIST_TICKETS_STATUS_MUTATION](payload) {
    this.ticketsList = payload;
  }

  @Mutation
  [Mutations.SET_LIST_ALL_TICKETS_MUTATION](payload) {
    this.allTickets = payload;
  }

  @Mutation
  [Mutations.SET_LIST_TICKETS_LINKS_MUTATION](payload) {
    this.ticketLinks = payload;
  }

  @Action
  async [Actions.FETCH_LIST_SELECT_TICKETS]() {
    const { data } = await service.v1.ticketsControllerGetListSelectTicket();
    this.context.commit(Mutations.SET_LIST_SELECT_TICKETS_MUTATION, data);
  }

  @Action
  async [Actions.FETCH_LIST_TICKETS_STATUS](payload) {
    try {
      const { data } =
        await service.v1.ticketStatusesControllerGetListTicketsByStatus({
          platformId: payload.data,
        });

      this.context.commit(Mutations.SET_LIST_TICKETS_STATUS_MUTATION, data);

      return data;
    } catch (error) {
      this.context.commit(Mutations.SET_ERROR_MUTATION, error);
    }
  }

  @Action
  async [Actions.FETCH_LIST_ALL_TICKETS](payload) {
    console.log(payload, "payload");
    const { data } = await service.v1.ticketsControllerFindAll({
      platformId: payload?.data.platformId,
      page: payload?.data.page,
      limit: payload?.data.rowsPerPage,
    });
    this.context.commit(Mutations.SET_LIST_ALL_TICKETS_MUTATION, data);
  }

  @Action
  async [Actions.SUBMIT_NEW_TICKET](payload: CreateNewTicketFormData) {
    console.log(Actions.SUBMIT_NEW_TICKET, "Started", payload);

    const request: CreateTicketRequestDto = {
      complexityId: payload.complexity,
      description: payload.description,
      dueDate: payload.dueDate,
      title: payload.ticketTitle,
      projectId: payload.projectId,
      parentTicketId: payload.relatesSection || payload.relatesPage,
      attachments: payload.dialogImageUrl,
      priorityId: payload.priority,
      assignedUserId: payload.assign,

      timeEstimated: parseDuration(payload.estimation),
      skills: payload.skills.map(
        (skill) =>
          ({
            [typeof skill === "string" ? "name" : "id"]: skill,
          } as unknown as NameOrIdDto)
      ),
      typeId: payload.type,
      userStoryScenarios: payload.userJourney
        ?.filter((x) => x)
        ?.map((x) => ({ description: x })),
      ticketAcceptanceCriterias: payload?.acceptanceTest
        ?.filter((x) => x)
        .map((x) => ({
          description: x,
        })),
    };

    return service.v1.ticketsControllerCreate(request);
  }
  @Action
  async [Actions.FETCH_LIST_TICKETS_LINKS](payload) {
    const { data } = await service.v1.ticketsControllerGetAllTicketsLinks({
      platformId: payload.data,
    });
    this.context.commit(Mutations.SET_LIST_TICKETS_LINKS_MUTATION, data);
  }
}

function parseDuration(s) {
  const m = /(?:(\d+)w)?(?:(\d+)d)?(?:(\d+)h)?(?:(\d+)m?)?/.exec(s);
  const input = {
    week: parseInt(m?.[1] || "0", 10),
    days: parseInt(m?.[2] || "0", 10),
    hours: parseInt(m?.[3] || "0", 10),
    minutes: parseInt(m?.[4] || "0", 10),
  };
  let hours = input.hours;
  hours += input.minutes / 60;
  hours += input.days * 8;
  hours += input.week * 5 * 8;
  return hours;
}
