import { setModal } from "./../../core/helpers/config";
import service from "@/service/devFactory";
import { createUniqueId, getChilds } from "./../../core/helpers/dataFunctions";
import { definitions } from "./../../types/supabase";
import { NodeType } from "./../../components/shared/mindmap/mindmapNodes";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { supabase } from "@/service/SupabaseService";
import { nest } from "@/core/helpers/dataFunctions";
import { MindmapNode } from "@/service/index";
import UserNodes from "@/core/config/mindmap-demo/userModule.json";
import PermissionModule from "@/core/config/mindmap-demo/permissionModule.json";
import VacationModule from "@/core/config/mindmap-demo/vacationModule.json";

import useTranslate from "@/core/composable/useTranslate";

@Module
export default class MindmapDemoModule extends VuexModule {
  nodes: MindmapNode[] = [];
  userId = Number(localStorage.getItem("userId"));
  get getNestedNodes() {
    return {
      data: {
        id: 1,
        text: `${useTranslate().translate("mindmap.myProject")}`,
        type: NodeType.PROJECT,
        userJourney: [""],
        acceptanceTest: [""],
        description: "Project Description",
      },
      children: nest({ items: this.nodes, link: "parentId" }),
      _updata: true,
    };
  }

  get getPageNodes() {
    return this.nodes.filter((node) => node.type == NodeType.PAGE);
  }

  get getPageAndSectionfrom() {
    return (nodeType: NodeType, parentId: number) => {
      if (nodeType == NodeType.SECTION) return { relatesPage: parentId };
      if (nodeType == NodeType.ACTION)
        return {
          relatesSection: parentId,
          relatesPage: this.nodes.find((node) => node.id == parentId)?.parentId,
        };
    };
  }

  get getSectionNodesByPageId() {
    return (pageId) =>
      this.nodes.filter(
        (node) => node.type == NodeType.SECTION && node.parentId == pageId
      );
  }

  get getMindmapUserId() {
    return this.userId;
  }

  @Mutation
  [Mutations.SET_USER_ID](payload) {
    this.userId = payload;
  }
  @Action
  async [Actions.SET_USERDETAILS_ACTIONS](payload) {
    console.log({ payload });
    const { data, error } =
      await service.mindmapCalculator.mindmapCalculatorControllerCreateUser(
        payload.data.value
      );
    localStorage.setItem("userId", data.toString());
    this.context.commit(Mutations.SET_USER_ID, data);
  }
  @Action
  [Actions.ADD_MODULE](payload) {
    let nodes = {};
    switch (payload) {
      case "user":
        nodes = UserNodes;
        break;
      case "permission":
        nodes = PermissionModule;
        break;

      case "vacation":
        nodes = VacationModule;
        break;

      default:
        break;
    }
    this.context.commit(Mutations.MERGE_MODULE_NODES, nodes);
  }

  @Mutation
  [Mutations.SET_NODES](payload) {
    console.log({ toMerge: payload });
    this.nodes = payload;
  }
  @Action
  async [Actions.LOAD_NODES](payload) {
    try {
      const res = await fetch(`/json/mindmap-samples/${payload}.json`);
      const nodes = await res.json();
      console.log({ nodes });

      this.context.commit(Mutations.SET_NODES, nodes);
    } catch {
      return { error: true };
    }
  }

  @Mutation
  [Mutations.MERGE_MODULE_NODES](payload) {
    console.log({ toMerge: payload });
    this.nodes = [...this.nodes, ...payload];
  }

  @Action
  [Actions.SUBMIT_MINDMAP_NODE](payload) {
    this.context.commit(Mutations.MERGE_MINDMAP_NODE, payload);
  }

  @Mutation
  [Mutations.MERGE_MINDMAP_NODE](payload) {
    const newNode = {
      ...payload,
      id: payload.id || createUniqueId(this.nodes.map((n) => n.id)),
      parentId:
        payload.relatesSection || payload.relatesPage || payload.parentId || 1,
    };
    console.log({ newNode });
    if (!payload.id) {
      this.nodes.push(newNode);
    } else {
      const editedNodeIndex = this.nodes.findIndex(
        (node) => node.id == payload.id
      );
      this.nodes[editedNodeIndex] = newNode;
    }
  }
  @Mutation
  [Mutations.DELETE_MINDMAP_NODE]({ id }) {
    const toDelete = [
      id,
      ...getChilds({ items: this.nodes, id, link: "parentId" }),
    ];
    console.log({ toDelete });

    this.nodes = this.nodes.filter((node) => !toDelete.includes(node.id));
  }

  @Action
  async [Actions.FETCH_CALCULATE_ESTIMATION]() {
    const { data } =
      await service.mindmapCalculator.mindmapCalculatorControllerCreate({
        nodes: this.nodes,
        userId: this.userId,
      });
    setModal("EstimatedModal", data);
  }

  // complexities
  complexities = [];
  get getComplexities() {
    return this.complexities;
  }
  @Mutation
  [Mutations.SET_COMPLEXITIES](payload) {
    this.complexities = payload;
  }
  @Action
  async [Actions.FETCH_COMPLEXITIES]() {
    if (this.complexities.length < 1) {
      const { data } = await supabase
        .from<definitions["ticket_complexities"]>("ticket_complexities")
        .select("*");
      console.log(data);
      this.context.commit(Mutations.SET_COMPLEXITIES, data);
    }
  }

  // nodeTypes
  nodeTypes: definitions["ticket_types"][] = [];
  get getNodeTypes() {
    return this.nodeTypes;
  }
  get getNodeTypeById() {
    return (id) => this.nodeTypes.find((type) => type.id == id);
  }
  get getNodeTypeByName() {
    return (name) =>
      this.nodeTypes.find(
        (type) => type.name.toLowerCase() == name.toLowerCase()
      );
  }
  @Mutation
  [Mutations.SET_NODE_TYPES](payload) {
    this.nodeTypes = payload;
  }
  @Action
  async [Actions.FETCH_NODE_TYPES]() {
    if (this.nodeTypes.length < 1) {
      const { data } = await supabase
        .from<definitions["ticket_types"]>("ticket_types")
        .select("*");
      this.context.commit(Mutations.SET_NODE_TYPES, data);
    }
  }
}
