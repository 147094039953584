import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";
import { GetAllPlatformMenuResponseDto } from "@/service/index";
import service from "@/service/devFactory";

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
}
export interface ModalOption {
  closeable?: boolean;
  isClose?: boolean;
}

interface Modal {
  type: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  payload: any;
  options?: ModalOption;
}

interface ModalInfo {
  modalState: Modal;
}

interface Breadcrumb {
  title: string;
  pageBreadcrumbPath: Array<string>;
}

interface StoreInfo {
  breadcrumbs: Breadcrumb;
}

interface MenuPlatforms {
  menuPlatform: GetAllPlatformMenuResponseDto;
}
@Module
export default class ConfigModule
  extends VuexModule
  implements StoreInfo, ModalInfo, StoreInfo, MenuPlatforms
{
  config = layoutConfig;
  initial = layoutConfig;
  modalState = { type: "", payload: {}, options: {} };
  breadcrumbs = {} as Breadcrumb;
  menuPlatform = {} as GetAllPlatformMenuResponseDto;

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

  get getModalState(): Modal {
    return this.modalState;
  }

  /**
   * Get breadcrumb object for current page
   * @returns object
   */
  get getBreadcrumbs(): Breadcrumb {
    return this.breadcrumbs;
  }

  /**
   * Get breadcrumb array for current page
   * @returns object
   */
  get pageBreadcrumbPath(): Array<string> {
    return this.breadcrumbs.pageBreadcrumbPath;
  }

  /**
   * Get current page title
   * @returns string
   */
  get pageTitle(): string {
    return this.breadcrumbs.title;
  }

  get getMenuPlatform(): GetAllPlatformMenuResponseDto {
    return this.menuPlatform;
  }

  @Mutation
  [Mutations.SET_LAYOUT_CONFIG](payload): void {
    this.config = payload;
  }

  @Mutation
  [Mutations.RESET_LAYOUT_CONFIG]() {
    this.config = Object.assign({}, this.initial);
  }

  @Mutation
  [Mutations.OVERRIDE_LAYOUT_CONFIG](): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem("config") || "{}")
    );
  }

  @Mutation
  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](payload): void {
    this.config = merge(this.config, payload);
  }

  @Mutation
  [Mutations.SET_MODAL_MUTATION](payload) {
    this.modalState = payload;
  }

  @Mutation
  [Mutations.SET_BREADCRUMB_MUTATION](payload) {
    this.breadcrumbs = payload;
  }

  @Mutation
  [Mutations.SET_MENU_PLATFORM_MUTATION](payload) {
    this.menuPlatform = payload;
  }

  @Action
  [Actions.FETCH_MODAL](payload) {
    this.context.commit(Mutations.SET_MODAL_MUTATION, payload);
  }
  @Action
  [Actions.SET_BREADCRUMB_ACTION](payload) {
    this.context.commit(Mutations.SET_BREADCRUMB_MUTATION, payload);
  }

  @Action
  async [Actions.FETCH_MENU_PLATFORM]() {
    await service.v1
      .platformsControllerFindMenuPlatforms()
      .then((res) => {
        this.context.commit(Mutations.SET_MENU_PLATFORM_MUTATION, res.data);
      })
      .catch((error) => {
        this.context.commit(
          Mutations.SET_MENU_PLATFORM_MUTATION,
          error.error.error
        );
      });
  }
}
