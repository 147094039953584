/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MessageResponseDto {
  message: string;
}

export interface PermissionsGroupsResponseDto {
  name: string;
  groups: any[][];
}

export interface UserPermissionsDto {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  accountId: string;
  userId: string;
  roles: string[];
  workspaceId: string;
  type: string;
  permissionsGroups: PermissionsGroupsResponseDto[];
}

export interface JwtTokensDto {
  accessToken: string;
  refreshToken: string;
  user: UserPermissionsDto;
}

export interface SignInDto {
  email: string;
  password: string;
}

export interface RefreshTokenDto {
  refreshToken: string;
}

export interface SuccessResponseDto {
  collectionName: object;
  data: object;
  options: object;
}

export interface SubscribeUserResponseDto {
  message: string;
  jwtTokens: JwtTokensDto;
}

export interface SubscribeAdminWebsiteRequestDto {
  name: string;
  image?: string;
  sizeWebsite: '1-1' | '2-10' | '10-50' | '50+';
}

export interface SubscribeAdminBusinessRequestDto {
  name: string;
  shortenedDescriptor?: string;
  businessType: 'it' | 'bank';
  description?: string;
  email: string;
}

export interface SubscribeAdminCardRequestDto {
  name: string;
  number?: string;
  month: number;
  year: number;
  cvv: number;
}

export interface SubscribeSuperAdminRequest {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  password: string;
  website: SubscribeAdminWebsiteRequestDto;
  business: SubscribeAdminBusinessRequestDto;
  card?: SubscribeAdminCardRequestDto;
}

export interface SubscribeMemberRequestDto {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  positionType: 'Full Stack' | 'Web Developer' | 'Expert Referencing';
  skills: string[];
}

export interface SubscribePluginFigmaRequestDto {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
}

export interface EmailResetPasswordDto {
  email: string;
}

export interface ResetPasswordDto {
  token: string;
  newPassword: string;
}

export interface UserGroupRequestDto {
  email: string;
  firstName: string;
  lastName: string;
  groups: number[];
}

export type User = object;

export interface AddUserToGroupRequestDto {
  memberId: number;
  groupId: number;
}

export interface CreatePermissionDto {
  /** @maxLength 200 */
  name: string;
}

export interface UpdatePermissionDto {
  /** @maxLength 200 */
  name?: string;
}

export interface CreateTicketStatusDto {
  name: string;
}

export interface UpdateTicketStatusDto {
  name?: string;
  id: number;
}

export interface GetStateResponseDto {
  name: string;
}

export interface GetComplexityResponseDto {
  name: string;
  score: number;
}

export interface GetCategoryResponseDto {
  name: string;
}

export interface GetProjectResponseDto {
  name: string;
  description?: string;
}

export interface GetUserResponseDto {
  email: string;
  firstName: string;
  lastName: string;
}

export interface GetPriorityResponseDto {
  name: string;
  score: string;
}

export interface GetTicketDescriptionKeyDto {
  id: number;
  description?: string;
  key: string;
}

export interface GetUserStoryScenarioResponseDto {
  description?: string;
}

export interface GetTicketAcceptenceCriterionDto {
  description?: string;
}

export interface GetSkillResponseDto {
  name: string;
}

export interface BaseTypeDto {
  id: number;
}

export interface GetCommentResponseDto {
  /** @maxLength 64 */
  message: string;
  user: BaseTypeDto;
  ticket: BaseTypeDto;
}

export interface GetTicketStatusResDto {
  description?: string;
  /** @format date-time */
  dueDate?: string;
  timeEstimated?: number;
  timeSpent?: number;
  status: GetStateResponseDto;
  complexity: GetComplexityResponseDto;
  ticketType: GetCategoryResponseDto;
  project: GetProjectResponseDto;
  reporterUser: GetUserResponseDto;
  creatorUser: GetUserResponseDto;
  assignedUser: GetUserResponseDto;
  priority: GetPriorityResponseDto;
  parentTicket?: GetTicketDescriptionKeyDto;
  key: string;
  userStoryScenarios: GetUserStoryScenarioResponseDto[];
  ticketAcceptanceCriterias: GetTicketAcceptenceCriterionDto[];
  skills: GetSkillResponseDto[];
  comments?: GetCommentResponseDto[];
}

export interface GetStatusTicketsResponseDto {
  name: string;
  tickets?: GetTicketStatusResDto[];
}

export interface CreateWorkflowDto {
  workflowName: string;
  workflowDesc: string;
}

export interface UpdateWorkflowDto {
  workflowName?: string;
  workflowDesc?: string;
}

export interface GroupPermissionRequestDto {
  name: string;
  permissions: BaseTypeDto[];
}

export interface GroupeDto {
  id: number;
  name: string;
}

export interface GroupResponseDto {
  id: number;
  name: string;
  permission: GroupeDto[];
}

export interface AllGroupsResponseDto {
  group: GroupResponseDto[];
}

export type Group = object;

export interface CreateCommentDto {
  message: string;
  user: number;
}

export interface UpdateCommentDto {
  message?: string;
  user?: number;
}

export interface NameOrIdDto {
  id?: number | null;
  name?: string | null;
}

export interface GetPlatformFilesResponseDto {
  id: number;
  fileTitle: string;
}

export interface TechnologiesResponseDto {
  id: number;
  name: string;
  type: NameOrIdDto;
}

export interface ProjectResponseDto {
  name: string;
  type: NameOrIdDto;
  technologies: TechnologiesResponseDto[];
}

export interface PlatformResponseDto {
  id: number;
  name: string;
  ticketsPrefix: string;
  description?: string;
  activities: NameOrIdDto[];
  /** @format date-time */
  releaseDate: string;
  platformFiles: GetPlatformFilesResponseDto[];
  groups: BaseTypeDto[];
  adminUser: BaseTypeDto;
  projects: ProjectResponseDto[];
  languages: BaseTypeDto[];
}

export interface CreatePlatformFilesRequestDto {
  fileTitle?: string;
}

export interface CreateTypesRequestDto {
  id: number;
  name: string;
  technologies: string[];
}

export interface CreatePlatformRequestDto {
  name: string;
  ticketsPrefix: string;
  description?: string;
  /** @format date-time */
  releaseDate: string;
  activities: string[];
  platformFiles?: CreatePlatformFilesRequestDto[];
  groups: number[];
  adminUser: number;
  languages: number[];
  types: CreateTypesRequestDto[];
}

export interface PlatformGroupRequestDto {
  platformId: number;
  groupId: number;
}

export interface GetAllPlatformMenuResponseDto {
  platforms: NameOrIdDto[];
}

export interface GetMembersResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface GetPlatformResponseDto {
  activities: NameOrIdDto[];
  groups: NameOrIdDto[];
  members: GetMembersResponseDto[];
  types: NameOrIdDto[];
  languages: NameOrIdDto[];
}

export interface GetPlatformPagResponseDto {
  id: number;
  name: string;
  advancement: number;
  /** @format date-time */
  dueDate: string;
  members: number;
  status: string;
}

export interface AllPlatformsResponseDto {
  platforms: GetPlatformPagResponseDto[];
}

export interface GetUserRequestDto {
  firstName: string;
  lastName: string;
}

export interface GetPlatformRequestDto {
  id: number;
  name: string;
  adminUser: GetUserRequestDto[];
}

export interface GetProjectOverviewResponseDto {
  id: number;
  name: string;
  description?: string;
  amplicationAppId: string;
  technologies: NameOrIdDto[];
}

export interface GetGroupRequestDto {
  name: string;
}

export interface GetUserMemberResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  positionType?: 'Full Stack' | 'Web Developer' | 'Expert Referencing' | null;
  groups: GetGroupRequestDto[];
}

export interface GetTaskResponseDto {
  id: number;
  title: string;
  description?: string;
  /** @format date-time */
  dueDate?: string;
  assignedUser: GetUserRequestDto[];
}

export interface GetPlatformOverviewResponseDto {
  platform: GetPlatformRequestDto;
  projects: GetProjectOverviewResponseDto[];
  listMembers: GetUserMemberResponseDto[];
  latestFiles: GetPlatformFilesResponseDto[];
  tasks: GetTaskResponseDto[];
}

export interface GetTicketFileResponseDto {
  fileName: string;
  directory: string;
  title: string;
}

export interface GetTicketResponseDto {
  id: number;
  description?: string;
  /** @format date-time */
  dueDate?: string;
  timeEstimated?: number;
  timeSpent?: number;
  status: GetStateResponseDto;
  complexity: GetComplexityResponseDto;
  ticketType: GetCategoryResponseDto;
  project: GetProjectResponseDto;
  reporterUser: GetUserResponseDto;
  creatorUser: GetUserResponseDto;
  assignedUser: GetUserResponseDto;
  priority: GetPriorityResponseDto;
  parentTicket: GetTicketDescriptionKeyDto;
  key: string;
  userStoryScenarios: GetUserStoryScenarioResponseDto[];
  ticketAcceptanceCriterias: GetTicketAcceptenceCriterionDto[];
  skills: GetSkillResponseDto[];
  ticketFiles?: GetTicketFileResponseDto[];
  comments?: GetCommentResponseDto[];
}

export interface CreateUserStoryScenarioDto {
  description?: string;
}

export interface CreateTicketAcceptanceCriterionRequestDto {
  description?: string;
}

export interface CreateTicketRequestDto {
  typeId?: number;
  priorityId?: number;
  title: string;
  description?: string;
  userStoryScenarios?: CreateUserStoryScenarioDto[];
  ticketAcceptanceCriterias?: CreateTicketAcceptanceCriterionRequestDto[];
  parentTicketId?: number;
  skills: NameOrIdDto[];
  complexityId?: number;
  status?: NameOrIdDto;
  key?: string;
  timeSpent?: number;
  assignedUserId?: number;
  projectId: number;
  /** @format date-time */
  dueDate?: string;
  timeEstimated?: number;
  attachments?: string[];
}

export interface TicketTypeResponseDto {
  id: number;
  name: string;
}

export interface CreateTicketPriorityDto {
  name: string;
  score: number;
}

export interface SkillRequestDto {
  name: string;
}

export interface UpdateComplexityDto {
  name?: string;
  score?: number;
}

export interface GetListSelectTicketResponseDto {
  ticketTypes: TicketTypeResponseDto[];
  ticketPriorities: CreateTicketPriorityDto[];
  skills?: SkillRequestDto[];
  ticketComplexities: UpdateComplexityDto[];
}

export interface AllTicketsResponseDto {
  ticket: GetTicketResponseDto[];
}

export interface GetAllTicketResponseDto {
  id: number;
  text: string;
  /** @format date-time */
  start_date: string;
  duration: number;
  open: boolean;
  parent?: number;
  color: string;
}

export interface GetLinksTicketsResponseDto {
  id: number;
  source: number;
  target: number;
  type: string;
}

export interface GetTicketsLinksResponseDto {
  data: GetAllTicketResponseDto;
  links: GetLinksTicketsResponseDto;
}

export interface MindmapNode {
  id: number;
  text: string;
  description: string;
  type: number;
  userJourney: string[];
  acceptanceTest: string[];
  parentId: number;
}

export interface CreateMindmapCalculatorRequestDto {
  nodes: MindmapNode[];
  userId: number;
}

export interface CreateMindmapCalculatorResponseDto {
  periodInDays: number;
  costInEuros: number;
}

export interface CreateMindmapUserRequestDto {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
}

export interface ExcelDataDtoInput {
  email: string;
  uid: string;
  tableData: string[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/nest/';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  public setBaseApiParams = (data: RequestParams) => {
    this.baseApiParams = this.mergeRequestParams(data);
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Api v1
 * @version 1.0
 * @baseUrl /nest/
 * @contact
 *
 * The boilerplate API for nestjs devs
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AppControllerSayHello
   * @request GET:/
   */
  appControllerSayHello = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/`,
      method: 'GET',
      ...params,
    });

  githubWebhooks = {
    /**
     * No description
     *
     * @name GithubWebhooksControllerPushRestrictedGithubEvents
     * @request POST:/github-webhooks/push_with_github_event
     */
    githubWebhooksControllerPushRestrictedGithubEvents: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/github-webhooks/push_with_github_event`,
        method: 'POST',
        ...params,
      }),
  };
  v1 = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignIn
     * @request POST:/v1/auth/sign-in
     */
    authControllerSignIn: (data: SignInDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: JwtTokensDto;
        },
        MessageResponseDto
      >({
        path: `/v1/auth/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerGetUserByAccessToken
     * @request GET:/v1/auth/token
     */
    authControllerGetUserByAccessToken: (
      query: {
        token: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: SuccessResponseDto;
        },
        any
      >({
        path: `/v1/auth/token`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSubscribeAdmin
     * @request POST:/v1/auth/subscribe-super-admin
     */
    authControllerSubscribeAdmin: (data: SubscribeSuperAdminRequest, params: RequestParams = {}) =>
      this.request<
        {
          data?: SubscribeUserResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/auth/subscribe-super-admin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSubscribeMember
     * @request POST:/v1/auth/subscribe-member
     */
    authControllerSubscribeMember: (data: SubscribeMemberRequestDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: SubscribeUserResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/auth/subscribe-member`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSubscribePluginFigma
     * @request POST:/v1/auth/subscribe-plugin-figma
     */
    authControllerSubscribePluginFigma: (data: SubscribePluginFigmaRequestDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: SubscribeUserResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/auth/subscribe-plugin-figma`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerAddUserWithGroups
     * @request POST:/v1/users/invite-member
     * @secure
     */
    usersControllerAddUserWithGroups: (data: UserGroupRequestDto, params: RequestParams = {}) =>
      this.request<User, User>({
        path: `/v1/users/invite-member`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetAllVerifiedUsers
     * @request GET:/v1/users
     * @secure
     */
    usersControllerGetAllVerifiedUsers: (params: RequestParams = {}) =>
      this.request<
        {
          data?: User;
        },
        User
      >({
        path: `/v1/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerAddMemberToGroup
     * @request POST:/v1/users/add-member-group
     * @secure
     */
    usersControllerAddMemberToGroup: (data: AddUserToGroupRequestDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: MessageResponseDto;
        },
        User | void
      >({
        path: `/v1/users/add-member-group`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerRemoveMemberToGroup
     * @request DELETE:/v1/users/remove-member-in-group
     * @secure
     */
    usersControllerRemoveMemberToGroup: (data: AddUserToGroupRequestDto, params: RequestParams = {}) =>
      this.request<User, User>({
        path: `/v1/users/remove-member-in-group`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags upload-files
     * @name UploadFilesControllerUploadFile
     * @request POST:/v1/upload-files/upload-file
     * @secure
     */
    uploadFilesControllerUploadFile: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v1/upload-files/upload-file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags upload-files
     * @name UploadFilesControllerUploadFiles
     * @request POST:/v1/upload-files/uploads
     * @secure
     */
    uploadFilesControllerUploadFiles: (
      data: {
        files?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v1/upload-files/uploads`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags upload-files
     * @name UploadFilesControllerFindFile
     * @request GET:/v1/upload-files/files/{originalname}
     * @secure
     */
    uploadFilesControllerFindFile: (originalname: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v1/upload-files/files/${originalname}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags permissions
     * @name PermissionsControllerCreate
     * @request POST:/v1/permissions
     * @secure
     */
    permissionsControllerCreate: (data: CreatePermissionDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/permissions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags permissions
     * @name PermissionsControllerUpdate
     * @request PATCH:/v1/permissions/{id}
     * @secure
     */
    permissionsControllerUpdate: (id: string, data: UpdatePermissionDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/permissions/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags permissions
     * @name PermissionsControllerRemove
     * @request DELETE:/v1/permissions/{id}
     * @secure
     */
    permissionsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/permissions/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket Statuses
     * @name TicketStatusesControllerCreate
     * @request POST:/v1/ticket-statuses
     * @secure
     */
    ticketStatusesControllerCreate: (data: CreateTicketStatusDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/ticket-statuses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket Statuses
     * @name TicketStatusesControllerFindAll
     * @request GET:/v1/ticket-statuses/ticketStatus
     * @secure
     */
    ticketStatusesControllerFindAll: (params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/ticket-statuses/ticketStatus`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket Statuses
     * @name TicketStatusesControllerFindOne
     * @request GET:/v1/ticket-statuses/ticket-status/{id}
     * @secure
     */
    ticketStatusesControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/ticket-statuses/ticket-status/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket Statuses
     * @name TicketStatusesControllerUpdate
     * @request PATCH:/v1/ticket-statuses/{id}
     * @secure
     */
    ticketStatusesControllerUpdate: (id: number, data: UpdateTicketStatusDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/ticket-statuses/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket Statuses
     * @name TicketStatusesControllerRemove
     * @request DELETE:/v1/ticket-statuses/{id}
     * @secure
     */
    ticketStatusesControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/ticket-statuses/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket Statuses
     * @name TicketStatusesControllerGetListTicketsByStatus
     * @request GET:/v1/ticket-statuses/list-tickets-by-status
     * @secure
     */
    ticketStatusesControllerGetListTicketsByStatus: (
      query?: {
        platformId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: GetStatusTicketsResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/ticket-statuses/list-tickets-by-status`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workflows
     * @name WorkflowsControllerCreate
     * @request POST:/v1/workflows
     * @secure
     */
    workflowsControllerCreate: (data: CreateWorkflowDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/workflows`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workflows
     * @name WorkflowsControllerFindAll
     * @request GET:/v1/workflows
     * @secure
     */
    workflowsControllerFindAll: (params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/workflows`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workflows
     * @name WorkflowsControllerFindOne
     * @request GET:/v1/workflows/{id}
     * @secure
     */
    workflowsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/workflows/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workflows
     * @name WorkflowsControllerUpdate
     * @request PATCH:/v1/workflows/{id}
     * @secure
     */
    workflowsControllerUpdate: (id: number, data: UpdateWorkflowDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/workflows/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workflows
     * @name WorkflowsControllerRemove
     * @request DELETE:/v1/workflows/{id}
     * @secure
     */
    workflowsControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: any;
        },
        User | void
      >({
        path: `/v1/workflows/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GroupsControllerCreate
     * @request POST:/v1/groups
     * @secure
     */
    groupsControllerCreate: (data: GroupPermissionRequestDto, params: RequestParams = {}) =>
      this.request<User, User>({
        path: `/v1/groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GroupsControllerFindAll
     * @request GET:/v1/groups
     * @secure
     */
    groupsControllerFindAll: (
      query?: {
        page?: number;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: AllGroupsResponseDto;
        },
        User
      >({
        path: `/v1/groups`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GroupsControllerFindOne
     * @request GET:/v1/groups/{id}
     * @secure
     */
    groupsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupResponseDto;
        },
        User
      >({
        path: `/v1/groups/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GroupsControllerUpdate
     * @request PUT:/v1/groups/{id}
     * @secure
     */
    groupsControllerUpdate: (id: number, data: GroupPermissionRequestDto, params: RequestParams = {}) =>
      this.request<User, User>({
        path: `/v1/groups/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GroupsControllerRemove
     * @request DELETE:/v1/groups/{id}
     * @secure
     */
    groupsControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<User, User>({
        path: `/v1/groups/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name TicketCommentsControllerCreate
     * @request POST:/v1/comments
     * @secure
     */
    ticketCommentsControllerCreate: (data: CreateCommentDto, params: RequestParams = {}) =>
      this.request<
        {
          Comment?: GetCommentResponseDto;
        },
        User | void
      >({
        path: `/v1/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name TicketCommentsControllerFindAllComments
     * @request GET:/v1/comments
     * @secure
     */
    ticketCommentsControllerFindAllComments: (
      query?: {
        limit?: number;
        page?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          Comment?: any;
        },
        User | void
      >({
        path: `/v1/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name TicketCommentsControllerFindOne
     * @request GET:/v1/comments/{id}
     * @secure
     */
    ticketCommentsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          Comment?: GetCommentResponseDto;
        },
        User | void
      >({
        path: `/v1/comments/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name TicketCommentsControllerUpdate
     * @request PATCH:/v1/comments/{id}
     * @secure
     */
    ticketCommentsControllerUpdate: (id: number, data: UpdateCommentDto, params: RequestParams = {}) =>
      this.request<
        {
          Comment?: any;
        },
        User | void
      >({
        path: `/v1/comments/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name TicketCommentsControllerRemove
     * @request DELETE:/v1/comments/{id}
     * @secure
     */
    ticketCommentsControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          Comment?: any;
        },
        User | void
      >({
        path: `/v1/comments/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name TicketCommentsControllerSearchCommentsByText
     * @request GET:/v1/comments/comments_search/{text}
     * @secure
     */
    ticketCommentsControllerSearchCommentsByText: (text: string, params: RequestParams = {}) =>
      this.request<
        {
          Comment?: any;
        },
        User | void
      >({
        path: `/v1/comments/comments_search/${text}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comments
     * @name TicketCommentsControllerSearchCommentsByTextFromUserById
     * @request GET:/v1/comments/user/{userId}/comments_search/{text}
     * @secure
     */
    ticketCommentsControllerSearchCommentsByTextFromUserById: (
      text: string,
      userId: number,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          Comment?: any;
        },
        User | void
      >({
        path: `/v1/comments/user/${userId}/comments_search/${text}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platforms
     * @name PlatformsControllerCreate
     * @request POST:/v1/platforms
     * @secure
     */
    platformsControllerCreate: (data: CreatePlatformRequestDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: PlatformResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/platforms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platforms
     * @name PlatformsControllerAddPlatformToGroup
     * @request POST:/v1/platforms/platform-group
     * @secure
     */
    platformsControllerAddPlatformToGroup: (data: PlatformGroupRequestDto, params: RequestParams = {}) =>
      this.request<User, MessageResponseDto>({
        path: `/v1/platforms/platform-group`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platforms
     * @name PlatformsControllerFindMenuPlatforms
     * @request GET:/v1/platforms/menu-platforms
     * @secure
     */
    platformsControllerFindMenuPlatforms: (params: RequestParams = {}) =>
      this.request<
        {
          data?: GetAllPlatformMenuResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/platforms/menu-platforms`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platforms
     * @name PlatformsControllerFindOneFormPlatform
     * @request GET:/v1/platforms/form-platform
     * @secure
     */
    platformsControllerFindOneFormPlatform: (params: RequestParams = {}) =>
      this.request<
        {
          data?: GetPlatformResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/platforms/form-platform`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platforms
     * @name PlatformsControllerFindAll
     * @request GET:/v1/platforms/all-platforms
     * @secure
     */
    platformsControllerFindAll: (
      query?: {
        page?: number;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: AllPlatformsResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/platforms/all-platforms`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platforms
     * @name PlatformsControllerFindOne
     * @request GET:/v1/platforms/{id}
     * @secure
     */
    platformsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GetPlatformOverviewResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/platforms/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name TicketsControllerFindOne
     * @request GET:/v1/tickets/ticket/{id}
     * @secure
     */
    ticketsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          Ticket?: GetTicketResponseDto;
        },
        User | void
      >({
        path: `/v1/tickets/ticket/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name TicketsControllerCreate
     * @request POST:/v1/tickets
     * @secure
     */
    ticketsControllerCreate: (data: CreateTicketRequestDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: MessageResponseDto;
        },
        any
      >({
        path: `/v1/tickets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name TicketsControllerGetListSelectTicket
     * @request GET:/v1/tickets/list-select-ticket
     * @secure
     */
    ticketsControllerGetListSelectTicket: (params: RequestParams = {}) =>
      this.request<
        {
          data?: GetListSelectTicketResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/tickets/list-select-ticket`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name TicketsControllerFindAll
     * @request GET:/v1/tickets/all-tickets
     * @secure
     */
    ticketsControllerFindAll: (
      query?: {
        platformId?: number;
        page?: number;
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: AllTicketsResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/tickets/all-tickets`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name TicketsControllerGetAllTicketsLinks
     * @request GET:/v1/tickets/tickets-Links
     * @secure
     */
    ticketsControllerGetAllTicketsLinks: (
      query?: {
        platformId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: GetTicketsLinksResponseDto;
        },
        MessageResponseDto
      >({
        path: `/v1/tickets/tickets-Links`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRefreshToken
     * @request POST:/auth/refresh-token
     */
    authControllerRefreshToken: (data: RefreshTokenDto, params: RequestParams = {}) =>
      this.request<
        {
          data?: JwtTokensDto;
        },
        User | MessageResponseDto
      >({
        path: `/auth/refresh-token`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogout
     * @request DELETE:/auth/logout/{token}
     * @secure
     */
    authControllerLogout: (token: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: MessageResponseDto;
        },
        User
      >({
        path: `/auth/logout/${token}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogoutAll
     * @request DELETE:/auth/logout-all
     * @secure
     */
    authControllerLogoutAll: (params: RequestParams = {}) =>
      this.request<void, User>({
        path: `/auth/logout-all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSendEmailResetPassword
     * @request POST:/auth/send_email_reset_password
     */
    authControllerSendEmailResetPassword: (data: EmailResetPasswordDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/auth/send_email_reset_password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPassword
     * @request POST:/auth/reset-password
     */
    authControllerResetPassword: (data: ResetPasswordDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/auth/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetVerifiedUserById
     * @request GET:/users/get-verified-user-by-id/{id}
     * @secure
     */
    usersControllerGetVerifiedUserById: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: User;
        },
        User | void
      >({
        path: `/users/get-verified-user-by-id/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  v2 = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetAllVerifiedUsersV2
     * @request GET:/v2/users
     * @secure
     */
    usersControllerGetAllVerifiedUsersV2: (
      query?: {
        limit?: any;
        number?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v2/users`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  mindmapCalculator = {
    /**
     * No description
     *
     * @tags Mindmap Calculator
     * @name MindmapCalculatorControllerCreate
     * @request POST:/mindmap-calculator
     */
    mindmapCalculatorControllerCreate: (data: CreateMindmapCalculatorRequestDto, params: RequestParams = {}) =>
      this.request<CreateMindmapCalculatorResponseDto, any>({
        path: `/mindmap-calculator`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mindmap Calculator
     * @name MindmapCalculatorControllerCreateUser
     * @request POST:/mindmap-calculator/user-info
     */
    mindmapCalculatorControllerCreateUser: (data: CreateMindmapUserRequestDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/mindmap-calculator/user-info`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  figma = {
    /**
     * No description
     *
     * @tags Mindmap Calculator
     * @name FigmaControllerCreate
     * @request POST:/figma
     */
    figmaControllerCreate: (data: ExcelDataDtoInput, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/figma`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
