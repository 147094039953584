import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import service from "@/service/devFactory";

let hasBaseParamWorkspace = false;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: () =>
      import(
        /* webpackChunkName: "layout" */ "@/components/layouts/mainLayout/Layout.vue"
      ),
    children: [
      {
        path: "/home",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/main/dashboard/dashboardAdmin/DashboardAdmin.vue"
          ),
      },
      {
        path: "ticket/:ticketKey",
        name: "ticketDetails",
        component: () =>
          import(
            /* webpackChunkName: "ticket_details" */ "@/views/main/ticketDetails/TicketDetails.vue"
          ),
      },
      {
        path: "/platform/:id",
        name: "platform",
        component: () =>
          import(
            /* webpackChunkName: "platform" */ "@/components/layouts/Platform.vue"
          ),
        children: [
          {
            path: "overview",
            name: "platform-overview",
            component: () =>
              import(
                /* webpackChunkName: "platform_overview" */ "@/views/main/platform/_children/overview/Overview.vue"
              ),
          },
          {
            path: "amplication/:projectId",
            name: "amplication",
            component: () =>
              import(
                /* webpackChunkName: "amplication" */ "@/views/main/platform/_children/Amplication.vue"
              ),
          },
          {
            path: "targets",
            name: "platform_targets",
            component: () =>
              import(
                /* webpackChunkName: "platform_targets" */ "@/views/main/platform/_children/targets/Targets.vue"
              ),
          },
          {
            path: "files",
            name: "files",
            component: () =>
              import(
                /* webpackChunkName: "platform_files" */ "@/views/main/platform/_children/Files.vue"
              ),
          },
          {
            path: "mindmap/:projectId",
            name: "mindmap",
            component: () =>
              import(
                /* webpackChunkName: "project_mindmap" */ "@/views/main/platform/_children/Mindmap.vue"
              ),
          },
          {
            path: "activity",
            name: "platform-activity",
            component: () =>
              import(
                /* webpackChunkName: "platform_activity" */ "@/views/main/platform/_children/Activity.vue"
              ),
          },
        ],
      },
      {
        path: "/dashboard/member",
        name: "dashboard-member",
        component: () =>
          import(
            /* webpackChunkName: "dashboard_member" */ "@/views/main/dashboard/dashboardMember/DashboardMember.vue"
          ),
      },
      {
        path: "/dashboard/admin",
        name: "dashboard-admin",
        component: () =>
          import(
            /* webpackChunkName: "dashboard_admin" */ "@/views/main/dashboard/dashboardAdmin/DashboardAdmin.vue"
          ),
      },
      {
        path: "/howWorks",
        name: "howWorks",
        component: () =>
          import(
            /* webpackChunkName: "how_it_works" */ "@/views/main/HowWorks.vue"
          ),
      },
      {
        path: "/platform/all-platforms",
        name: "all-platforms",
        component: () =>
          import(
            /* webpackChunkName: "all_platforms" */ "@/views/main/platform/AllPlatforms.vue"
          ),
      },
      {
        path: "/users-management/role/:id",
        name: "website-role",
        component: () =>
          import(
            /* webpackChunkName: "roles_view" */ "@/views/main/userManagement/roles/ViewRoles.vue"
          ),
      },
      {
        path: "/userManagement/users-management",
        name: "users-management",
        component: () =>
          import(
            /* webpackChunkName: "users_management" */ "@/views/main/userManagement/UsersManagement.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "auth_layout" */ "@/components/layouts/Auth.vue"
      ),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import(/* webpackChunkName: "sign_in" */ "@/views/auth/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import(
            /* webpackChunkName: "sign_up" */
            "@/views/auth/signUpAdmin/SignUpAdmin.vue"
          ),
      },
      {
        path: "/sign-up-member",
        name: "sign-up-member",
        component: () =>
          import(
            /* webpackChunkName: "sign_up_member" */ "@/views/auth/SignUpMember.vue"
          ),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import(
            /* webpackChunkName: "password_reset" */ "@/views/auth/PasswordReset.vue"
          ),
      },
      {
        path: "/plugin-figma-password-reset/:token",
        name: "plugin-figma-password-reset",
        component: () =>
          import(
            /* webpackChunkName: "plugin_figma_password_reset" */ "@/views/auth/PluginFigmaResetPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/mindmap-demo",
    name: "minimap-demo",
    component: () =>
      import(
        /* webpackChunkName: "mindmap_samples" */ "@/views/mindmapDemo/MindmapSamples.vue"
      ),
  },
  {
    name: "mindmap-sample",
    path: "/mindmap-demo/:sampleId",
    component: () =>
      import(
        /* webpackChunkName: "mindmap_demo" */ "@/views/mindmapDemo/MindmapDemo.vue"
      ),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "error_404" */ "@/views/errors/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () =>
      import(/* webpackChunkName: "error_500" */ "@/views/errors/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  if (from.path !== "/sign-up") {
    if (!hasBaseParamWorkspace && to.query.workspace) {
      service.setBaseApiParams({
        headers: {
          "x-tenant-workspace": String(to.query.workspace),
        },
      });
      hasBaseParamWorkspace = true;
    }

    if (from.query.workspace && !to.query.workspace) {
      next({ path: to.path, query: { workspace: from.query.workspace } });
      return;
    } else {
      next();
      return;
    }
  } else {
    next();
    return;
  }
});

export default router;
