
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { ElConfigProvider } from "element-plus";
import fr from "element-plus/lib/locale/lang/fr";
import en from "element-plus/lib/locale/lang/en";
import { useI18n } from "vue-i18n";
import service from "@/service/devFactory";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  components: {
    ElConfigProvider,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();

    const currentLang = computed(() => {
      if (i18n.locale.value === "en") {
        return en;
      } else {
        return fr;
      }
    });
    store.dispatch(Actions.FETCH_USER_TOKEN);
    const tokenUser = computed(() => {
      return store.getters.userToken;
    });

    store.dispatch(Actions.FETCH_USER_PROFILE);

    onMounted(() => {
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      service.setBaseApiParams({
        headers: { Authorization: "Bearer " + tokenUser.value },
      });
    });

    return {
      currentLang,
    };
  },
});
