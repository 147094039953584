import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import TicketModule from "@/store/modules/TicketModule";
import PlatformModule from "@/store/modules/PlatformModule";
import ErrorModule from "@/store/modules/ErrorModule";
import MindmapDemoModule from "@/store/modules/MindmapDemoModule";

config.rawError = true;

const store = createStore({
  modules: {
    BodyModule,
    ConfigModule,
    UserModule,
    TicketModule,
    PlatformModule,
    ErrorModule,
    MindmapDemoModule,
  },
});

export default store;
