import { createI18n } from "vue-i18n";
import * as en from "@/core/i18n/en.json";
import * as fr from "@/core/i18n/fr.json";
const messages = { en: {}, fr: {} };
messages.en = JSON.parse(JSON.stringify(en)).default;
messages.fr = JSON.parse(JSON.stringify(fr)).default;
const i18n = createI18n({
  legacy: false,
  locale: "fr",
  globalInjection: true,
  fallbackLocale: "fr",
  messages,
});

export default i18n;
